import React, { useContext, useEffect, useRef, useState } from "react"
import { navigate } from "gatsby"
import { AuthContext } from "../../store/authContext"
import LogoutIcon from "../../../images/logout.png"
import PhoneIcon from "../../../images/phone.png"
import EmailIcon from "../../../images/email.png"
import CheckIcon from "../../../images/check-mark.png"
import axios from "axios"
import { toast } from "react-toastify"
import { CurrencyContext } from "../../store/currencyContext"
import { CountryContext } from "../../store/countryContext"

export default function AddressTab({
  showLoader,
  addresses,
  billingAddress,
  shippingAddress,
  countryContextData,
  setAddresses,
  setContactAddress,
  getStates,
  handleChangeAddress,
  handleSubmitAddress,
  billingCountryStatesRef,
  shippingCountryStatesRef,
  contactAddress,
  countryStates,
  isAddressSubmitted,
}) {
  return showLoader ? (
    <div className="loader_bg_auth">
      <div class="loader_auth"></div>
    </div>
  ) : (
    <div className="address_tab">
      {isAddressSubmitted && (
        <div className="save_address">
          <div className="check">
            <img src={CheckIcon} />
          </div>
          <span>Address changed successfully.</span>
        </div>
      )}
      {!addresses && (
        <>
          <p>
            The following addresses will be used on the checkout page by
            default.
          </p>
          <div className="add_adress_block">
            <div className="add_item">
              <div className="txts">
                <h4>Billing Address</h4>
                {billingAddress &&
                  Object.values(billingAddress).filter(item => item).length !==
                  0 ? (
                  Object.entries(billingAddress).map(item =>
                    item[0] === "country" ? (
                      <p>
                        {
                          countryContextData.filter(
                            country => country.code === item[1]
                          )[0]?.name
                        }
                      </p>
                    ) : item[0] === "state" ? (
                      <p>
                        {
                          billingCountryStatesRef?.current?.filter(
                            state => state.code === item[1]
                          )[0]?.name
                        }
                      </p>
                    ) : (
                      <p>{item[1]}</p>
                    )
                  )
                ) : (
                  <p>You have not set up this type of address yet.</p>
                )}
              </div>
              <span
                onClick={() => {
                  setAddresses("billing_address")
                  if (billingAddress) {
                    setContactAddress(_prevState => ({
                      ..._prevState,
                      ...billingAddress,
                    }))
                    getStates(billingAddress?.country)
                  }
                }}
              >
                {billingAddress &&
                  Object.values(billingAddress).filter(item => item).length !== 0
                  ? "Edit Billing address"
                  : "Add Billing address"}
              </span>
            </div>
            <div className="add_item">
              <div className="txts">
                <h4>Shipping Address</h4>
                {shippingAddress &&
                  Object.values(shippingAddress).filter(item => item).length !==
                  0 ? (
                  Object.entries(shippingAddress).map(item =>
                    item[0] === "country" ? (
                      <p>
                        {
                          countryContextData.filter(
                            country => country.code === item[1]
                          )[0]?.name
                        }
                      </p>
                    ) : item[0] === "state" ? (
                      <p>
                        {
                          shippingCountryStatesRef?.current?.filter(
                            state => state.code === item[1]
                          )[0]?.name
                        }
                      </p>
                    ) : (
                      <p>{item[1]}</p>
                    )
                  )
                ) : (
                  <p>You have not set up this type of address yet.</p>
                )}
              </div>
              <span
                onClick={() => {
                  setAddresses("shipping_address")
                  if (shippingAddress) {
                    setContactAddress(_prevState => ({
                      ..._prevState,
                      ...shippingAddress,
                    }))
                  }
                  getStates(shippingAddress?.country)
                }}
              >
                {shippingAddress &&
                  Object.values(shippingAddress).filter(item => item).length !== 0
                  ? "Edit Shipping address"
                  : "Add Shipping address"}
              </span>
            </div>
          </div>
        </>
      )}
      {addresses && (
        <>
          {addresses === "billing_address" ? (
            <h4>Billing Address</h4>
          ) : (
            <h4>Shipping Address</h4>
          )}
          <div className="address_form">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <label>
                  First Name <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={contactAddress.firstName}
                  onChange={handleChangeAddress}
                />
              </div>
              <div className="col-lg-6 col-md-12">
                <label>
                  Last Name <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="lastName"
                  value={contactAddress.lastName}
                  onChange={handleChangeAddress}
                />
              </div>
              <div className="col-sm-12">
                <label>Company name(Optional)</label>
                <input
                  type="text"
                  className="form-control"
                  name="company"
                  value={contactAddress.company}
                  onChange={handleChangeAddress}
                />
              </div>
              <div className="col-sm-12">
                <label>
                  Country/Region <span className="asterisk">*</span>
                </label>
                <select
                  className="form-control"
                  name="country"
                  value={
                    countryContextData
                      ? countryContextData.filter(
                        country => country.code === contactAddress.country
                      )[0]?.code
                      : ""
                  }
                  onChange={_e => {
                    handleChangeAddress(_e)
                    getStates(_e)
                  }}
                >
                  {countryContextData &&
                    countryContextData.map((country, index) => (
                      <option value={country?.code} key={index}>
                        {country.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-sm-12">
                <label>
                  Street Address <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  className="form-control mb-3"
                  name="address1"
                  value={contactAddress.address1}
                  onChange={handleChangeAddress}
                />
                <input
                  type="text"
                  className="form-control"
                  name="address2"
                  value={contactAddress.address2}
                  onChange={handleChangeAddress}
                />
              </div>
              <div className="col-sm-12">
                <label>
                  Suburb <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="city"
                  value={contactAddress.city}
                  onChange={handleChangeAddress}
                />
              </div>
              {countryStates.length > 0 && (
                <div className="col-sm-12">
                  <label>
                    State <span className="asterisk">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="state"
                    value={
                      countryStates
                        ? countryStates.filter(
                          state => state.code === contactAddress.state
                        )[0]?.code
                        : ""
                    }
                    onChange={_ev => handleChangeAddress(_ev)}
                  >
                    <option value="">Please select</option>
                    {countryStates &&
                      countryStates.map((state, index) => (
                        <option value={state?.code} key={index}>
                          {state.name}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              <div className="col-sm-12">
                <label>
                  Postcode / ZIP <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="postcode"
                  value={contactAddress.postcode}
                  onChange={handleChangeAddress}
                />
              </div>
              {addresses === "billing_address" && (
                <>
                  <div className="col-sm-12">
                    <label>
                      Phone <span className="asterisk">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="phone"
                      value={contactAddress.phone}
                      onChange={handleChangeAddress}
                    />
                  </div>
                  <div className="col-sm-12">
                    <label>
                      Email Address <span className="asterisk">*</span>
                    </label>
                    <input
                      type="email"
                      pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                      className="form-control"
                      name="email"
                      value={contactAddress.email}
                      onChange={handleChangeAddress}
                    />
                  </div>
                </>
              )}
              <div className="col-sm-12">
                <button onClick={handleSubmitAddress} className="gray_button">
                  Save address
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
