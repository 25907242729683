import AccountDetailsTab from "../account/tabs/AccountDetailsTab"
import AddressTab from "../account/tabs/AddressTab"
import DashboardTab from "../account/tabs/DashboardTab"
import DownloadsTab from "../account/tabs/DownloadsTab"
import OrdersTab from "../account/tabs/OrdersTab"
import PaymentTab from "../account/tabs/PaymentTab"
import PointsTab from "../account/tabs/PointsTab"
import LeftSideCartSection from "../cart/LeftSideCartSection"
import RightSideCartSection from "../cart/RightSideCartSection"

export const sortingOptions = [
  { value: "menu_order", label: "Default sorting" },
  { value: "popularity", label: "Sort by popularity" },
  { value: "rating", label: "Sort by average rating" },
  { value: "date", label: "Sort by latest" },
  { value: "price", label: "Sort by price: low to high" },
  { value: "price-desc", label: "Sort by price: high to low" },
]

export const menuHeaders = {
  dashboard: "My account",
  orders: "Orders",
  downloads: "Downloads",
  address: "Addresses",
  payment: "Payment methods",
  account_details: "Account details",
  points: "Points",
}

export const menuComponents = {
  dashboard: DashboardTab,
  orders: OrdersTab,
  downloads: DownloadsTab,
  address: AddressTab,
  payment: PaymentTab,
  account_details: AccountDetailsTab,
  points: PointsTab,
}

export const initialFilterValue = {
  bullionType: "",
  after: "1",
  category: "bullion",
  composition: "",
  country: "",
  denomination: "",
  finish: "",
  first: 12,
  grade: "",
  maxPrice: "",
  minPrice: "",
  mintageRangeEnd: "",
  mintageRangeStart: "",
  orderby: "",
  region: "",
  relation: "",
  search: "",
  set: "",
  strikeType: "",
  theme: "",
  weight: "",
  yearRangeEnd: "",
  yearRangeStart: "",
}

export const initialSaleFilterValue = {
  bullionType: "",
  after: "1",
  category: "",
  composition: "",
  country: "",
  denomination: "",
  finish: "",
  first: 12,
  grade: "",
  maxPrice: "",
  minPrice: "",
  mintageRangeEnd: "",
  mintageRangeStart: "",
  orderby: "",
  region: "",
  relation: "",
  search: "",
  set: "",
  strikeType: "",
  theme: "",
  weight: "",
  yearRangeEnd: "",
  yearRangeStart: "",
  tag: "clearance",
}

export const initialSelectionsValue = {
  bullionType: "",
  after: "1",
  category: "",
  composition: "",
  country: "",
  denomination: "",
  finish: "",
  first: 12,
  grade: "",
  maxPrice: "",
  minPrice: "",
  mintageRangeEnd: "",
  mintageRangeStart: "",
  orderby: "",
  region: "",
  relation: "",
  search: "",
  set: "",
  strikeType: "",
  theme: "",
  weight: "",
  yearRangeEnd: "",
  yearRangeStart: "",
}

export const initialHomeValue = {
  after: "1",
  first: 6,
  orderby: "date",
}

export const cartComponents = {
  head: null,
  leftSideSection: LeftSideCartSection,
  rightSideSection: RightSideCartSection,
}

export const cartChangeAddressShippingAddressErrorMessage = {
  country: "Country is required.",
  city: "City is required.",
  // state: "State is required.",
  postcode: "Postcode / ZIP is required.",
}

export const CART_msg = {
  FTAPOC: "Failed To add product on Cart",
  PAOCS: "Product added to cart!",
  PUOCS: "Product updated on cart!",
  PRFCS: "Product remove from cart!",
  FTUPOC: "Failed to update product on Cart",
  CPUS: "Cart product updated successfully!",
  FTRPFC: "Failed To remove product from Cart",
  CIR: "Successfully removed item from cart",
  FTAC: "Failed To apply coupoun",
  CA: "Coupon applied successfully!",
  FTRCFC: "Failed To remove coupoun from cart",
  CR: "Coupon Removed Successfully!",
  FTCSA: "Failed To change shipping address",
  SACS: "Shipping address changed successfully!",
  FTUSAM: "Failed to update shipping method",
  SAMU: "Shipping method updated successfully!",
  FTAP: "Failed to apply points",
  PAS: "Points applied successfully!",
  FTALCI: "Failed to add local cart Item",
  IAICS: "Items added in cart successfully!",
  IFA: "insurance fee added!",
  IFNA: "insurance fee not added !",
  IFFTA: "insurance fee fail to add",
  IFRFC: "insurance fee removed From cart",
  YOP: "Your order successfully placed",
}

export const AUTH_msg = {
  PEVE: "Please enter valid email!",
}
