import React, { createContext, useEffect, useState } from "react"
import { checkIsTokenExpireQuery, getFreshToken } from "../helper/query"
import { callGraphQLQuery } from "../helper/callQL"

const authContextSessionValue =
  typeof window !== "undefined"
    ? sessionStorage.getItem("authContextSessionValue")
    : ""

export const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  const [authContextData, setAuthContextData] = useState(
    authContextSessionValue ?? ""
  )

  async function checkYokenIsExpire(token) {
    try {
      if (token) {
        let response = await callGraphQLQuery(
          checkIsTokenExpireQuery(token),
          null,
          token
        )
        // Handle the response
        if (response.status === 200) {
          const isTokenExpired = response.data.data.isTokenExpired;
          if (isTokenExpired) {

            const refreshToken = sessionStorage.getItem("refreshToken")

            if (refreshToken) {

              let refetchTokenReq = await callGraphQLQuery(
                getFreshToken(refreshToken),
                null,
                refreshToken
              );
              if (refetchTokenReq.status === 200) {
                sessionStorage.setItem("authContextSessionValue", refetchTokenReq?.data?.data?.refreshJwtAuthTokenNew?.accessToken)
                sessionStorage.setItem("refreshToken", refetchTokenReq?.data?.data?.refreshJwtAuthTokenNew?.refreshToken)
              }

            }

          }
        }
      }
    } catch (error) {
      // setLoader(false)
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    checkYokenIsExpire(authContextSessionValue)
  }, [authContextSessionValue])


  return (
    <AuthContext.Provider value={{ authContextData, setAuthContextData }}>
      {children}
    </AuthContext.Provider>
  )
}
