import axios from "axios"

export const callRestApi = async (
    url,
    variables = null,
    token = null
) => {
    try {
        const headers = {
            "Content-Type": "application/json",
        }
        const currency = JSON.parse(localStorage.getItem("currencyContextSessionValue"));
        // Convert the object to a query string
        const filteredParams = Object.fromEntries(
            Object.entries(variables).filter(
              ([_, value]) => value !== null && value !== undefined && value !== ""
            )
          );
          
          // Convert the filtered object to a query string
          const queryString = new URLSearchParams({...filteredParams, curentCurrency: currency.code || 'AUD'}).toString();

        // Add the `?` prefix
        const fullQueryString = `?${queryString}`;

        if (token) headers["Authorization"] = `Bearer ${token}`

        const sessionID = sessionStorage.getItem("sessionID")
        if (sessionID) headers["X-Custom-Sessionid"] = `${sessionID}`


        if (currency) headers["X-Current-Currency"] = currency.code || "AUD";

        const response = await axios.get(`${process.env.WP_API_REST_URL}/${url}${fullQueryString}`,{
            headers,
        })
        return response
    } catch (error) {
        console.error("Error fetching data:", error)
    }
}
