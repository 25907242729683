import React, { createContext, useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { clearAllCookies, getCookie, setCookie } from "../../utills/cookies"
import {
  addLocalCartProductsFromLocalStorageToLogedInUserCartQuery,
  addLocalCartProductsToWPSessionUsingUniqueMutationIdQuery,
  addProductToCart,
  addProductToCartNew,
  applayCouponCodeQuery,
  applayCouponCodeQueryUsingSession,
  applyInsuranceFeeQuery,
  applyInsuranceFeeQueryUsingSession,
  applyPointsDiscountQuery,
  getCartIteams,
  getCartIteamsNew,
  getPointsByUserIdQuery,
  mutationCheckoutQuery,
  mutationCheckoutQueryForBankTransferUsingSession,
  mutationCheckoutQueryForPaypalUsingSession,
  mutationCheckoutQueryPaypal,
  PaymentGatewaysQuery,
  removeCouponCodeQuery,
  removeCouponCodeQueryUsingSession,
  removeSingleCartItemUsingProduct,
  removeSingleCartItemUsingProductNew,
  shippingAddressQuery,
  updateCartItemQtyAndAmout,
  updateCartItemQtyAndAmoutNew,
  updateShippingAddressQueryBasedOnSession,
  updateShippingMethodQuery,
  updateShippingMethodQueryUsingSession,
  applyFeesOnPaymentMethodChangeNew,
  applyFeesOnPaymentMethodChange,
} from "../helper/query"
import { callGraphQLQuery } from "../helper/callQL"
import CustomToast from "../helper/toast"
import { AuthContext } from "./authContext"
import { navigate } from "gatsby"
import { CART_msg } from "../helper/constants"
import {
  getCartFromLocalStorage,
  removeCartFromLocalStorage,
  setCartToLocalStorage,
} from "../helper/systemStorage"
import Utils from "../helper/utills"
import { useLocation } from "@reach/router"

export const CartContext = createContext()

export const CartContextProvider = ({ children }) => {
  const authContext = useContext(AuthContext)
  const location = useLocation()
  const [cartContextData, setCartContextData] = useState(null)
  const [cartItemCount, setCartItemCount] = useState(0)
  const [showLoader, setLoader] = useState(false)
  const [cartItemsLoading, setCartItemsLoading] = useState(false)
  const [totalRemainPoits, setTotalRemainPoits] = useState(0)
  const [paymentGateways, setPaymentGateways] = useState([])
  const [cartProductDatabaseIdList, setCartProductDatabaseIdList] = useState([])
  const [
    cartProductDatabaseIdAndQtyForNonAuthUser,
    setCartProductDatabaseIdAndQtyForNonAuthUser,
  ] = useState([])

  const collectDatabaseIds = cartData => {
    // Use map to directly extract the databaseId values
    return cartData?.cart?.contents?.nodes?.map(
      item => item.product.node.databaseId
    )
  }

  const { authContextData: authToken } = authContext

  useEffect(() => {
    getCart()
    if (authToken) {
      getPointsByUserIdApi()
    } else {
      // setCartContextData(getCartFromLocalStorage())
    }
  }, [authToken])

  /**
   * Cart Features Without Authenticated Users
   */

  // Update local storage whenever cart changes
  useEffect(() => {

    setCartItemCount(cartContextData?.contents?.itemCount ?? 0)

    setCookie(
      "woocommerce_items_in_cart_cointChest",
      cartContextData?.contents?.itemCount ?? 0,
      7
    )

    setCartProductDatabaseIdList(
      cartContextData?.contents?.nodes?.map(item =>
        item?.product?.node?.id
          ? +item?.product?.node?.id
          : +item?.product?.node?.databaseId
      ) ?? []
    )
  }, [cartContextData])

  const addLocalCartProductToStateAndLocalStorageForWithoutAuthenticatedUsers =
    (product, cb, quantity = 1) => {

      let newCart = { ...cartContextData }

      // Ensure the nodes array exists
      if (!newCart?.contents?.nodes) {
        const emptyCart = {
          contents: {
            itemCount: 0,
            nodes: [],
            productCount: 0,
          },
          appliedCouponsData: [],
          subtotal: "$0.00",
          shippingTotal: "$0.00",
          needsShippingAddress: false,
          chosenShippingMethods: [],
          availableShippingMethods: [],
          total: "$0.00",
        }

        setCartContextData(emptyCart)
        newCart = { ...emptyCart }
      }

      // Find the existing product in the nodes array
      const existingProduct = newCart.contents.nodes.find(
        item => item.product.node.id === product.id
      )

      if (existingProduct) {
        // Update quantity and total for the existing product
        existingProduct.quantity += quantity
        const newTotal = (
          parseFloat(existingProduct.total.slice(1)) +
          parseFloat(product.price) * quantity
        ).toFixed(2)
        existingProduct.total = `$${newTotal}`
        existingProduct.subtotal = `$${newTotal}`
      } else {
        // Add new product to cart
        const newItem = {
          key: product.id,
          product: {
            node: product,
          },
          quantity,
          total: `$${(parseFloat(product.price) * quantity).toFixed(2)}`,
          subtotal: `$${(parseFloat(product.price) * quantity).toFixed(2)}`,
        }
        newCart.contents.nodes.push(newItem)
        newCart.contents.productCount += 1
      }

      // Update cart's item count, subtotal, and total
      newCart.contents.itemCount += quantity
      const newSubtotal = (
        parseFloat(newCart.subtotal.slice(1)) +
        parseFloat(product.price) * quantity
      ).toFixed(2)
      newCart.subtotal = `$${newSubtotal}`
      newCart.total = newCart.subtotal

      // Update the cart in the state and local storage
      setCartContextData(newCart)

      if (cb) {
        cb(product.id)
      }
      // setCartInLocalStorage(newCart);  // Ensure cart is saved to localStorage
    }

  const removeLocalCartProductFromStateAndLocalStorageForWithoutAuthenticatedUsers =
    (productId, quantity = 1) => {
      let newCart = { ...cartContextData }

      // Find the existing product index in the nodes array
      const existingProductIndex = newCart.contents.nodes.findIndex(
        item => item.product.node.id === productId
      )

      if (existingProductIndex >= 0) {
        const existingProduct = newCart.contents.nodes[existingProductIndex]

        // Check if we are reducing quantity or removing the item
        if (existingProduct.quantity > quantity) {
          // Reduce quantity and update totals
          existingProduct.quantity -= quantity
          const newTotal = (
            parseFloat(existingProduct.total.slice(1)) -
            parseFloat(existingProduct.product.node.price) * quantity
          ).toFixed(2)
          existingProduct.total = `$${newTotal}`
          existingProduct.subtotal = `$${newTotal}`

          // Update cart's item count, subtotal, and total
          newCart.contents.itemCount -= quantity
          const newSubtotal = (
            parseFloat(newCart.subtotal.slice(1)) -
            parseFloat(existingProduct.product.node.price) * quantity
          ).toFixed(2)
          newCart.subtotal = `$${newSubtotal}`
          newCart.total = newCart.subtotal
        } else {
          // Remove the item entirely if quantity drops to zero or below
          newCart.contents.itemCount -= existingProduct.quantity
          newCart.subtotal = `$${(
            parseFloat(newCart.subtotal.slice(1)) -
            parseFloat(existingProduct.total.slice(1))
          ).toFixed(2)}`
          newCart.total = newCart.subtotal

          newCart.contents.nodes.splice(existingProductIndex, 1)
          newCart.contents.productCount -= 1
        }

        // If the cart is empty after removal, reset the cart
        if (newCart.contents.nodes.length === 0) {
          removeCartFromLocalStorage()
        } else {
          // Otherwise, update the cart context and local storage
          setCartContextData(newCart)
          // setCartInLocalStorage(newCart);
        }
      }
    }

  const deleteLocalCartProductFromStateAndLocalStorageForWithoutAuthenticatedUsers =
    productId => {
      let newCart = { ...cartContextData }

      // Find the index of the product to be deleted
      const existingProductIndex = newCart.contents.nodes.findIndex(
        item => item.product.node.id === productId
      )

      if (existingProductIndex >= 0) {
        const existingProduct = newCart.contents.nodes[existingProductIndex]

        // Deduct the product's quantity and total price from the cart's item count, subtotal, and total
        newCart.contents.itemCount -= existingProduct.quantity
        const newSubtotal = (
          parseFloat(newCart.subtotal.slice(1)) -
          parseFloat(existingProduct.total.slice(1))
        ).toFixed(2)
        newCart.subtotal = `$${newSubtotal}`
        newCart.total = newCart.subtotal

        // Remove the product from the nodes array
        newCart.contents.nodes.splice(existingProductIndex, 1)
        newCart.contents.productCount -= 1

        // If the cart is empty after deletion, reset the cart
        if (newCart.contents.nodes.length === 0) {
          // console.log("last product=======")
          clearLocalCartProductFromStateAndLocalStorageForWithoutAuthenticatedUsers()
        } else {
          // Otherwise, update the cart context and local storage
          setCartContextData(newCart)
          // setCartInLocalStorage(newCart);
        }
      }

      CustomToast(CART_msg.PRFCS, "success")
    }

  // Clear cart
  const clearLocalCartProductFromStateAndLocalStorageForWithoutAuthenticatedUsers =
    () => {
      const emptyCart = {
        contents: {
          itemCount: 0,
          nodes: [],
          productCount: 0,
        },
        appliedCouponsData: [],
        subtotal: "$0.00",
        shippingTotal: "$0.00",
        needsShippingAddress: false,
        chosenShippingMethods: [],
        availableShippingMethods: [],
        total: "$0.00",
      }
      setCartContextData(emptyCart)
      removeCartFromLocalStorage()
    }

  /**
   * Cart Features With Authenticated Users
   */

  function ifCartApiWillNotSuccess() {
    const catCount = getCookie("woocommerce_items_in_cart_cointChest")
    setCartItemCount(+catCount ?? 0)
  }

  function ifCartApiWillSuccess(cart) {
    // console.log("=======*****", cart)
    setCartItemCount(cart?.contents?.itemCount ?? 0)
    setCartContextData(cart)
  }

  const ifCartIsEmptyOrFailedToDataInCart = () => {
    setCartItemCount(0)
    setCartContextData([])
    setCookie("woocommerce_items_in_cart_cointChest", 0, 7)
    setCartProductDatabaseIdList([])
    checkIfCartIfEmptyAndUserTryToAccessCheckoutPage()
  }

  function checkIfCartIfEmptyAndUserTryToAccessCheckoutPage() {
    if (location?.pathname === "/checkout/") {
      navigate("/")
      return CustomToast("Cannot checkout without anything in the cart")
    }
  }

  const getCart = async (tokenAfterLogin, itzLoading = true) => {
    try {
      setLoader(true)
      itzLoading && setCartItemsLoading(true)

      if (!authToken) {
        const sessionID = sessionStorage.getItem("sessionID")
        const currency = localStorage.getItem("currencyContextSessionValue")

        const response = await callGraphQLQuery(
          getCartIteamsNew(currency.code || "AUD", sessionID || null)
        )

        // Handle the response
        if (response.status === 200) {
          if (!response?.data?.data?.getCartNew?.cart) {
            ifCartIsEmptyOrFailedToDataInCart()
          } else {
            setCartItemCount(
              response?.data?.data?.getCartNew?.cart?.contents?.itemCount ?? 0
            )
            if (response?.data?.data?.getCartNew?.cart?.contents?.itemCount === 0) {
              checkIfCartIfEmptyAndUserTryToAccessCheckoutPage();
            }
            setCartContextData(response?.data?.data?.getCartNew?.cart);
            setCookie(
              "woocommerce_items_in_cart_cointChest",
              response?.data?.data?.getCartNew?.cart?.contents?.itemCount ?? 0,
              7
            )

            setCartProductDatabaseIdList(
              collectDatabaseIds(response?.data?.data) ?? []
            )

            localStorage.setItem("country", response?.data?.data?.getCartNew?.cart?.customerData.country || "AU");
          }
        }
      } else {
        const response = await callGraphQLQuery(
          getCartIteams(),
          null,
          tokenAfterLogin ?? authToken
        )
        // Handle the response
        if (response.status === 200) {
          if (!response?.data?.data?.cart) {
            const catCount = getCookie("woocommerce_items_in_cart_cointChest")
            setCartItemCount(+catCount ?? 0)
            checkIfCartIfEmptyAndUserTryToAccessCheckoutPage();
          } else {
            setCartItemCount(
              response?.data?.data?.cart?.contents?.itemCount ?? 0
            )
            if (response?.data?.data?.cart?.contents?.itemCount === 0) {
              checkIfCartIfEmptyAndUserTryToAccessCheckoutPage();
            }
            setCartContextData(response?.data?.data?.cart)
            setCookie(
              "woocommerce_items_in_cart_cointChest",
              response?.data?.data?.cart?.contents?.itemCount ?? 0,
              7
            )

            setCartProductDatabaseIdList(
              collectDatabaseIds(response?.data?.data) ?? []
            )
          }
        }
      }
      // setLoader(false)
    } catch (error) {
      // setLoader(false)
      console.error("Error fetching data:", error)
    } finally {
      setLoader(false)
      setCartItemsLoading(false)
    }
  }

  const addProductsToCart = async (id, qyt, cb, errCB) => {
    try {
      setLoader(true)

      if (!authToken) {
        const sessionID = sessionStorage.getItem("sessionID")

        const response = await callGraphQLQuery(
          addProductToCartNew(id, qyt, sessionID || null),
          null,
          null
        )

        // Handle the response
        if (response.status === 200) {
          if (!response?.data?.data?.addToCartNew?.cart) {
            errCB()

            ifCartApiWillNotSuccess()

            return CustomToast(
              response?.data?.errors[0]?.debugMessage ??
              response?.data?.errors[0]?.message ??
              CART_msg.FTAPOC
            )
          } else {
            if (cartItemCount === 0) {
              const currency = localStorage.getItem(
                "currencyContextSessionValue"
              )
              const defaultAddress = {
                postcode: "5000",
                country: "AU",
                city: "Adelaide",
                state: "SA",
              }

              const updateAddressFirstResponse = await callGraphQLQuery(
                updateShippingAddressQueryBasedOnSession(
                  defaultAddress,
                  response?.data?.data?.addToCartNew?.sessionId || null,
                  currency.code || "AUD"
                )
              )

              if (updateAddressFirstResponse.status === 200) {
                if (
                  !updateAddressFirstResponse?.data?.data
                    ?.updateShippingAddressNew
                ) {
                  return CustomToast(
                    updateAddressFirstResponse?.data?.errors[0]?.debugMessage ??
                    updateAddressFirstResponse?.data?.errors[0]?.message ??
                    CART_msg.FTAPOC
                  )
                } else {
                  cb(id)

                  sessionStorage.setItem(
                    "sessionID",
                    updateAddressFirstResponse?.data?.data
                      ?.updateShippingAddressNew?.sessionId
                  )
                  ifCartApiWillSuccess(
                    updateAddressFirstResponse?.data?.data
                      ?.updateShippingAddressNew?.cart ?? {}
                  )
                  CustomToast(CART_msg.PAOCS, "success")
                }
              }
            } else {
              cb(id)

              CustomToast(CART_msg.PAOCS, "success")

              ifCartApiWillSuccess(
                response?.data?.data?.addToCartNew?.cart ?? {}
              )

              if (response?.data?.data?.addToCartNew?.sessionId) {
                sessionStorage.setItem(
                  "sessionID",
                  response?.data?.data?.addToCartNew?.sessionId
                )
              }
            }
          }
        }
      } else {
        const response = await callGraphQLQuery(
          addProductToCart(id, qyt),
          null,
          authToken
        )

        // Handle the response
        if (response.status === 200) {
          if (!response?.data?.data?.addToCart?.cart) {
            errCB()

            ifCartApiWillNotSuccess()

            return CustomToast(
              response?.data?.errors[0]?.debugMessage ??
              response?.data?.errors[0]?.message ??
              CART_msg.FTAPOC
            )
          } else {
            cb(id)

            CustomToast(CART_msg.PAOCS, "success")

            ifCartApiWillSuccess(response?.data?.data?.addToCart?.cart ?? {})
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data: Add cart", error)
    } finally {
      setLoader(false)
    }
  }

  const updateCartItems = async (key, qyt) => {
    try {
      setLoader(true)

      if (!authToken) {
        const response = await callGraphQLQuery(
          updateCartItemQtyAndAmoutNew(key, qyt),
          null,
          null
        )

        // Handle the response
        if (response.status === 200) {
          if (!response?.data?.data?.updateItemQuantitiesNew?.cart) {
            // ifCartApiWillNotSuccess()

            return CustomToast(
              response?.data?.errors[0]?.debugMessage ??
              response?.data?.errors[0]?.message ??
              CART_msg.FTUPOC
            )
          } else {
            CustomToast(CART_msg.CPUS, "success")

            ifCartApiWillSuccess(
              response?.data?.data?.updateItemQuantitiesNew?.cart ?? {}
            )

            if (response?.data?.data?.updateItemQuantitiesNew?.sessionId) {
              sessionStorage.setItem(
                "sessionID",
                response?.data?.data?.updateItemQuantitiesNew?.sessionId
              )
            }
          }
        }
      } else {
        const response = await callGraphQLQuery(
          updateCartItemQtyAndAmout(key, qyt),
          null,
          authToken
        )
        // Handle the response
        if (response.status === 200) {
          if (!response?.data?.data?.updateItemQuantities?.cart) {
            // ifCartApiWillNotSuccess()

            return CustomToast(
              response?.data?.errors[0]?.debugMessage ??
              response?.data?.errors[0]?.message ??
              CART_msg.FTUPOC
            )
          } else {
            CustomToast(CART_msg.CPUS, "success")

            ifCartApiWillSuccess(
              response?.data?.data?.updateItemQuantities?.cart ?? {}
            )
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data: Update cart  QTY", error)
    } finally {
      setLoader(false)
    }
  }

  const removeSingleCartItemUsingProductId = async key => {
    try {
      setLoader(true)
      if (!authToken) {
        // return CustomToast("Please Login")
        const sessionID = sessionStorage.getItem("sessionID")

        const response = await callGraphQLQuery(
          removeSingleCartItemUsingProductNew(key, sessionID),
          null,
          null
        )

        // Handle the response
        if (response.status === 200) {
          if (!response?.data?.data?.removeCartNew?.cart) {
            // ifCartApiWillNotSuccess();
            ifCartIsEmptyOrFailedToDataInCart()

            // return CustomToast(
            //   response?.data?.errors[0]?.debugMessage ??
            //   response?.data?.errors[0]?.message ??
            //   CART_msg.FTRPFC
            // )
          } else {
            CustomToast(CART_msg.CIR, "success")

            ifCartApiWillSuccess(
              response?.data?.data?.removeCartNew?.cart ?? {}
            )

            if (response?.data?.data?.removeCartNew?.sessionId) {
              sessionStorage.setItem(
                "sessionID",
                response?.data?.data?.removeCartNew?.sessionId
              )
            }
          }
        }
      } else {
        const response = await callGraphQLQuery(
          removeSingleCartItemUsingProduct(key),
          null,
          authToken
        )
        // Handle the response
        if (response.status === 200) {
          if (!response?.data?.data?.removeItemsFromCart?.cart) {
            // ifCartApiWillNotSuccess();

            return CustomToast(
              response?.data?.errors[0]?.debugMessage ??
              response?.data?.errors[0]?.message ??
              CART_msg.FTRPFC
            )
          } else {
            CustomToast(CART_msg.CIR, "success")

            ifCartApiWillSuccess(
              response?.data?.data?.removeItemsFromCart?.cart ?? {}
            )
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data: Remove Cart", error)
    } finally {
      setLoader(false)
    }
  }

  async function applyCouponCodeApi(code, cb) {
    try {
      setLoader(true)

      if (!authToken) {
        const sessionID = sessionStorage.getItem("sessionID")

        const response = await callGraphQLQuery(
          applayCouponCodeQueryUsingSession(code, sessionID || null),
          null,
          null
        )

        // Handle the response
        if (response.status === 200) {
          if (!response?.data?.data?.applyCouponNew) {
            return CustomToast(
              response?.data?.errors[0]?.debugMessage ??
              response?.data?.errors[0]?.message ??
              CART_msg.FTAC
            )
          } else {
            if (cb) {
              cb()
            }

            CustomToast(CART_msg.CA, "success")

            ifCartApiWillSuccess(
              response?.data?.data?.applyCouponNew?.cart ?? {}
            )

            if (response?.data?.data?.applyCouponNew?.sessionId) {
              sessionStorage.setItem(
                "sessionID",
                response?.data?.data?.applyCouponNew?.sessionId
              )
            }
          }
        }
      } else {
        const response = await callGraphQLQuery(
          applayCouponCodeQuery(code),
          null,
          authToken
        )
        // Handle the response
        if (response.status === 200) {
          if (!response?.data?.data?.applyCoupon) {
            return CustomToast(
              response?.data?.errors[0]?.debugMessage ??
              response?.data?.errors[0]?.message ??
              CART_msg.FTAC
            )
          } else {
            if (cb) {
              cb()
            }

            CustomToast(CART_msg.CA, "success")

            ifCartApiWillSuccess(response?.data?.data?.applyCoupon?.cart ?? {})
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setLoader(false)
    }
  }

  async function removeCouponCodeApi(code) {
    try {
      setLoader(true)
      if (!authToken) {
        const sessionID = sessionStorage.getItem("sessionID")

        const response = await callGraphQLQuery(
          removeCouponCodeQueryUsingSession(code, sessionID || null),
          null,
          null
        )

        if (response.status === 200) {
          if (!response?.data?.data?.removeCouponNew) {
            return CustomToast(
              response?.data?.errors[0]?.debugMessage ??
              response?.data?.errors[0]?.message ??
              CART_msg.FTRCFC
            )
          } else {
            CustomToast(CART_msg.CR, "success")

            ifCartApiWillSuccess(
              response?.data?.data?.removeCouponNew?.cart ?? {}
            )

            if (response?.data?.data?.removeCouponNew?.sessionId) {
              sessionStorage.setItem(
                "sessionID",
                response?.data?.data?.removeCouponNew?.sessionId
              )
            }
          }
        }
      } else {
        const response = await callGraphQLQuery(
          removeCouponCodeQuery(code),
          null,
          authToken
        )
        // Handle the response
        if (response.status === 200) {
          if (!response?.data?.data?.removeCoupons) {
            return CustomToast(
              response?.data?.errors[0]?.debugMessage ??
              response?.data?.errors[0]?.message ??
              CART_msg.FTRCFC
            )
          } else {
            CustomToast(CART_msg.CR, "success")

            ifCartApiWillSuccess(
              response?.data?.data?.removeCoupons?.cart ?? {}
            )
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setLoader(false)
    }
  }

  async function changeShippingAddressApi(address, cb) {
    try {
      setLoader(true)
      if (!authToken) {
        const sessionID = sessionStorage.getItem("sessionID")
        const currency = localStorage.getItem("currencyContextSessionValue")
        const response = await callGraphQLQuery(
          updateShippingAddressQueryBasedOnSession(
            address,
            sessionID || null,
            currency.code || "AUD"
          )
        )
        // Handle the response
        if (response.status === 200) {
          if (!response?.data?.data?.updateShippingAddressNew) {
            return CustomToast(
              response?.data?.errors[0]?.debugMessage ??
              response?.data?.errors[0]?.message ??
              CART_msg.FTCSA
            )
          } else {
            if (cb) {
              cb()
            }
            sessionStorage.setItem(
              "sessionID",
              response?.data?.data?.updateShippingAddressNew?.sessionId
            )
            CustomToast(CART_msg.SACS, "success")
            ifCartApiWillSuccess(
              response?.data?.data?.updateShippingAddressNew?.cart ?? {}
            )
          }
        }
      } else {
        const response = await callGraphQLQuery(
          shippingAddressQuery(address),
          null,
          authToken
        )
        // Handle the response
        if (response.status === 200) {
          if (!response?.data?.data?.updateShippingAddress) {
            return CustomToast(
              response?.data?.errors[0]?.debugMessage ??
              response?.data?.errors[0]?.message ??
              CART_msg.FTCSA
            )
          } else {
            if (cb) {
              cb()
            }
            CustomToast(CART_msg.SACS, "success")
            ifCartApiWillSuccess(
              response?.data?.data?.updateShippingAddress?.cart ?? {}
            )
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setLoader(false)
    }
  }

  async function updateShippingMethodApi(shippingMethods) {
    try {
      setLoader(true)

      if (!authToken) {
        const sessionID = sessionStorage.getItem("sessionID")
        const response = await callGraphQLQuery(
          updateShippingMethodQueryUsingSession(
            shippingMethods,
            sessionID || null
          )
        )
        // Handle the response
        if (response.status === 200) {
          if (!response?.data?.data?.updateShippingMethodNew) {
            return CustomToast(
              response?.data?.errors[0]?.debugMessage ??
              response?.data?.errors[0]?.message ??
              CART_msg.FTUSAM
            )
          } else {
            sessionStorage.setItem(
              "sessionID",
              response?.data?.data?.updateShippingMethodNew?.sessionId
            )
            CustomToast(CART_msg.SAMU, "success")

            ifCartApiWillSuccess(
              response?.data?.data?.updateShippingMethodNew?.cart ?? {}
            )
          }
        }
      } else {
        const response = await callGraphQLQuery(
          updateShippingMethodQuery(shippingMethods),
          null,
          authToken
        )
        // Handle the response
        if (response.status === 200) {
          if (!response?.data?.data?.updateShippingMethod) {
            return CustomToast(
              response?.data?.errors[0]?.debugMessage ??
              response?.data?.errors[0]?.message ??
              CART_msg.FTUSAM
            )
          } else {
            CustomToast(CART_msg.SAMU, "success")

            ifCartApiWillSuccess(
              response?.data?.data?.updateShippingMethod?.cart ?? {}
            )
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setLoader(false)
    }
  }

  async function applyPointsDiscountApi(points, cb) {
    try {
      setLoader(true)
      const response = await callGraphQLQuery(
        applyPointsDiscountQuery(points),
        null,
        authToken
      )
      // Handle the response
      if (response.status === 200) {
        if (!response?.data?.data?.applyPointsDiscount) {
          return CustomToast(
            response?.data?.errors[0]?.debugMessage ??
            response?.data?.errors[0]?.message ??
            CART_msg.FTAP
          )
        } else {
          if (cb) {
            cb()
          }

          CustomToast(CART_msg.PAS, "success")

          ifCartApiWillSuccess(
            response?.data?.data?.applyPointsDiscount?.cart ?? {}
          )
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setLoader(false)
    }
  }

  async function getPointsByUserIdApi() {
    try {
      setLoader(true)

      const userDetails = JSON.parse(sessionStorage.getItem("userDetails"))
      const { databaseId } = userDetails || {}
      // Get the variables from user input

      if (!databaseId) {
        return
      }

      const variables = {
        userId: +databaseId,
      }

      const response = await callGraphQLQuery(
        getPointsByUserIdQuery(),
        variables,
        authToken
      )
      // Handle the response
      if (response.status === 200) {
        //console.log("============>poins get ", response)

        if (!response?.data?.data?.pointsByUserId) {
          CustomToast(response?.data?.errors[0]?.message)
          setLoader(false)
        } else {
          // CustomToast(response?.data?.data?.pointsByUserId?.totalRemainPoits);
          setTotalRemainPoits(
            response?.data?.data?.pointsByUserId?.totalRemainPoits ?? 0
          )
        }
      }
    } catch (error) {
      // setLoader(false)
      console.error("Error fetching data:", error)
    } finally {
      // setLoader(false);
    }
  }

  async function PaymentGatewaysApi() {
    try {
      setLoader(true)

      const response = await callGraphQLQuery(
        PaymentGatewaysQuery(),
        null,
        authToken
      )
      // Handle the response
      if (response.status === 200) {
        if (!response?.data?.data?.paymentGateways) {
          CustomToast(response?.data?.errors[0]?.message)
        } else {
          setPaymentGateways(response?.data?.data?.paymentGateways?.nodes ?? [])
        }
      }
    } catch (error) {
      // setLoader(false)
      console.error("Error fetching data:", error)
    } finally {
      setLoader(false)
    }
  }

  async function mutationCheckoutApi(payload) {
    try {
      setLoader(true)

      if (!authToken) {
        const sessionID = sessionStorage.getItem("sessionID")
        const response = await callGraphQLQuery(
          payload?.paymentMethod === "ppcp-gateway"
            ? mutationCheckoutQueryForPaypalUsingSession(
              payload,
              sessionID || null,
              payload.createAnAccount,
              payload.password
            )
            : mutationCheckoutQueryForBankTransferUsingSession(
              payload,
              sessionID || null,
              payload.createAnAccount,
              payload.password
            )
        )
        // Handle the response

        if (response.status === 200) {
          if (
            payload?.paymentMethod === "bacs" &&
            response?.data?.data?.processCustomCheckoutNew?.result !== "success"
          ) {
            CustomToast(response?.data?.errors[0]?.message)
            setLoader(false)
          } else if (
            payload?.paymentMethod === "bacs" &&
            response?.data?.data?.processCustomCheckoutNew?.result === "success"
          ) {
            CustomToast(CART_msg.YOP, "success")
            sessionStorage.setItem(
              "sessionID",
              response?.data?.data?.processCustomCheckoutNew?.sessionId
            )
            setTimeout(() => {
              if (typeof window !== "undefined") {
                sessionStorage.setItem(
                  "order-received",
                  JSON.stringify({
                    ...response?.data?.data?.processCustomCheckoutNew?.order,
                    date: response?.data?.data?.processCustomCheckoutNew?.order
                      ?.date
                      ? response?.data?.data?.processCustomCheckoutNew?.order
                        ?.date
                      : response?.data?.data?.processCustomCheckoutNew
                        ?.orderDate,
                  })
                )
              }

              navigate("/order-received")
            }, 500)
          }
          if (
            payload?.paymentMethod === "ppcp-gateway" &&
            response?.data?.data?.processCustomCheckoutNew?.result !== "success"
          ) {
            CustomToast(response?.data?.errors[0]?.message)
            setLoader(false)
          } else if (
            payload?.paymentMethod === "ppcp-gateway" &&
            response?.data?.data?.processCustomCheckoutNew?.result === "success"
          ) {
            CustomToast(CART_msg.YOP, "success")
            sessionStorage.setItem(
              "sessionID",
              response?.data?.data?.processCustomCheckoutNew?.sessionId
            )
            setTimeout(() => {
              if (typeof window !== "undefined") {
                sessionStorage.setItem(
                  "order-received",
                  JSON.stringify({
                    ...response?.data?.data?.processCustomCheckoutNew?.order,
                    date: response?.data?.data?.processCustomCheckoutNew?.order
                      ?.date
                      ? response?.data?.data?.processCustomCheckoutNew?.order
                        ?.date
                      : response?.data?.data?.processCustomCheckoutNew
                        ?.orderDate,
                  })
                )
              }

              navigate("/order-received")
            }, 500)
          }
        }
      } else {
        const response = await callGraphQLQuery(
          payload?.paymentMethod === "ppcp-gateway"
            ? mutationCheckoutQueryPaypal(payload)
            : mutationCheckoutQuery(payload),
          null,
          authToken
        )
        // Handle the response
        if (response.status === 200) {
          if (
            payload?.paymentMethod === "bacs" &&
            response?.data?.data?.checkout?.result !== "success"
          ) {
            CustomToast(response?.data?.errors[0]?.message)
            setLoader(false)
          } else if (
            payload?.paymentMethod === "bacs" &&
            response?.data?.data?.checkout?.result === "success"
          ) {
            CustomToast(CART_msg.YOP, "success")

            setTimeout(() => {
              if (typeof window !== "undefined") {
                sessionStorage.setItem(
                  "order-received",
                  JSON.stringify(response?.data?.data?.checkout?.order)
                )
              }

              navigate("/order-received")
            }, 500)
          }

          if (
            payload?.paymentMethod === "ppcp-gateway" &&
            !response?.data?.data?.processCustomCheckout?.success
          ) {
            CustomToast(response?.data?.errors[0]?.message)
            setLoader(false)
          } else if (
            payload?.paymentMethod === "ppcp-gateway" &&
            response?.data?.data?.processCustomCheckout?.success
          ) {
            CustomToast(CART_msg.YOP, "success")

            setTimeout(() => {
              if (typeof window !== "undefined") {
                sessionStorage.setItem(
                  "order-received",
                  JSON.stringify(
                    response?.data?.data?.processCustomCheckout?.order
                  )
                )
              }

              navigate("/order-received")
            }, 500)
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setLoader(false)
      getCart()
    }
  }

  async function addLocalCartProductsFromLocalStorageToLogedInUserCartApi(
    authToken
  ) {
    try {
      if (!cartProductDatabaseIdAndQtyForNonAuthUser?.length) {
        return getCart(authToken)
      }

      setLoader(true)
      const response = await callGraphQLQuery(
        addLocalCartProductsFromLocalStorageToLogedInUserCartQuery(
          JSON.parse(JSON.stringify(cartProductDatabaseIdAndQtyForNonAuthUser))
        ),
        null,
        authToken
      )
      // Handle the response
      if (response.status === 200) {
        //console.log(response?.data?.data)
        if (!response?.data?.data?.addCartItems) {
          return CustomToast(
            response?.data?.errors[0]?.debugMessage ??
            response?.data?.errors[0]?.message ??
            CART_msg.FTALCI
          )
        } else {
          // CustomToast(CART_msg.IAICS, "success")

          ifCartApiWillSuccess(response?.data?.data?.addCartItems?.cart ?? {})

          removeCartFromLocalStorage()
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setLoader(false)
    }
  }

  async function applyInsuranceFeeApi(value, cb) {
    try {
      setLoader(true)

      if (!authToken) {
        const sessionID = sessionStorage.getItem("sessionID")
        const response = await callGraphQLQuery(
          applyInsuranceFeeQueryUsingSession(value, sessionID || null)
        )
        // Handle the response
        if (response.status === 200) {
          // console.log("======--===1", 
          //   response?.data?.data?.applyInsuranceFeeNew?.message?.toLowerCase(),
          //   "======--===2",
          //   CART_msg.IFA?.toLowerCase(),
          //   "======--===3",
          //   response?.data?.data?.applyInsuranceFeeNew?.message?.toLowerCase(),
          //   "======--===4",
          //   CART_msg.IFNA?.toLowerCase()
          // )
          // if (
          //   response?.data?.data?.applyInsuranceFeeNew?.message?.toLowerCase() ===
          //   CART_msg.IFA?.toLowerCase() ||
          //   response?.data?.data?.applyInsuranceFeeNew?.message?.toLowerCase() ===
          //   CART_msg.IFNA?.toLowerCase()
          // ) {
          sessionStorage.setItem(
            "sessionID",
            response?.data?.data?.applyInsuranceFeeNew?.sessionId
          )

          ifCartApiWillSuccess(
            response?.data?.data?.applyInsuranceFeeNew?.cart ?? {}
          )

          CustomToast(response?.data?.data?.applyInsuranceFeeNew?.message?.toLowerCase(), "success")
          // CustomToast(
          //   response?.data?.data?.applyInsuranceFeeNew?.message?.toLowerCase() ===
          //     CART_msg.IFA?.toLowerCase()
          //     ? CART_msg.IFA
          //     : CART_msg.IFRFC,
          //   "success"
          // )
          // } 
          // else {
          //   return CustomToast(
          //     response?.data?.errors[0]?.debugMessage ??
          //     response?.data?.errors[0]?.message ??
          //     CART_msg.IFFTA
          //   )
          // }
        } else {
          return CustomToast(
            response?.data?.errors[0]?.debugMessage ??
            response?.data?.errors[0]?.message ??
            CART_msg.IFFTA
          )
        }
      } else {
        const response = await callGraphQLQuery(
          applyInsuranceFeeQuery(value),
          null,
          authToken
        )
        // Handle the response
        if (response.status === 200) {
          if (
            response?.data?.data?.applyInsuranceFee?.message?.toLowerCase() === CART_msg.IFA?.toLowerCase() ||
            response?.data?.data?.applyInsuranceFee?.message?.toLowerCase() === CART_msg.IFNA?.toLowerCase()
          ) {
            getCart()
            CustomToast(
              response?.data?.data?.applyInsuranceFee?.message?.toLowerCase() === CART_msg.IFA?.toLowerCase()
                ? CART_msg.IFA
                : CART_msg.IFRFC,
              "success"
            )
          } else {
            return CustomToast(
              response?.data?.errors[0]?.debugMessage ??
              response?.data?.errors[0]?.message ??
              CART_msg.IFFTA
            )
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setLoader(false)
    }
  }

  async function addLocalCartProductsToWPSessionUsingUniqueMutationIdApi() {
    try {
      if (!cartProductDatabaseIdAndQtyForNonAuthUser?.length) {
        return
      }

      const isUniqueIDExist = localStorage.getItem("cart_mutation_id")

      let uniqueId

      if (isUniqueIDExist) {
        uniqueId = isUniqueIDExist
      } else {
        uniqueId = Utils.generateUniqueId()
        localStorage.setItem("cart_mutation_id", uniqueId)
      }

      setLoader(true)
      const response = await callGraphQLQuery(
        addLocalCartProductsToWPSessionUsingUniqueMutationIdQuery(
          JSON.parse(JSON.stringify(cartProductDatabaseIdAndQtyForNonAuthUser)),
          uniqueId
        )
      )
      // Handle the response
      if (response.status === 200) {
        //console.log(response?.data?.data)
        if (!response?.data?.data?.addCartItems) {
          return CustomToast(
            response?.data?.errors[0]?.debugMessage ??
            response?.data?.errors[0]?.message ??
            CART_msg.FTALCI
          )
        } else {
          // CustomToast(CART_msg.IAICS, "success")

          ifCartApiWillSuccess(response?.data?.data?.addCartItems?.cart ?? {})

          removeCartFromLocalStorage()
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setLoader(false)
    }
  }

  const clearAllCookie = () => {
    setCartItemCount(0)
    setCartContextData(null)
    clearAllCookies()
  }

  async function applyFeeNonBankTransferBooleanOnly(paymentMethod) {
    try {
      setLoader(true)
      let response = null
      if (!authToken) {
        const sessionID = sessionStorage.getItem("sessionID")
        response = await callGraphQLQuery(
          applyFeesOnPaymentMethodChangeNew(paymentMethod, sessionID),
          null,
          null
        )
      } else {
        response = await callGraphQLQuery(
          applyFeesOnPaymentMethodChange(paymentMethod),
          null,
          authToken
        )
      }

      // Handle the response
      if (response.status === 200) {
        if (!authToken) {
          sessionStorage.setItem(
            "sessionID",
            response?.data?.data?.applyFeesOnPaymentMethodChangeNew?.sessionId
          )
          setCartContextData(
            response?.data?.data?.applyFeesOnPaymentMethodChangeNew?.cart
          )
        } else {
          setCartContextData(
            response?.data?.data?.applyFeesOnPaymentMethodChange?.cart
          )
        }
      }
    } catch (error) {
      // setLoader(false)
      console.error("Error fetching data:", error)
    } finally {
      setLoader(false)
    }
  }

  const value = {
    cartItemCount,
    setCartItemCount,
    setCartContextData,
    cartContextData,
    getCart,
    addProductsToCart,
    clearAllCookie,
    showLoader,
    setLoader,
    updateCartItems,
    removeSingleCartItemUsingProductId,
    cartItemsLoading,
    applyCouponCodeApi,
    changeShippingAddressApi,
    removeCouponCodeApi,
    updateShippingMethodApi,
    applyPointsDiscountApi,
    getPointsByUserIdApi,
    totalRemainPoits,
    setTotalRemainPoits,
    PaymentGatewaysApi,
    paymentGateways,
    setPaymentGateways,
    mutationCheckoutApi,
    cartProductDatabaseIdList,
    setCartProductDatabaseIdList,
    addLocalCartProductToStateAndLocalStorageForWithoutAuthenticatedUsers,
    removeLocalCartProductFromStateAndLocalStorageForWithoutAuthenticatedUsers,
    clearLocalCartProductFromStateAndLocalStorageForWithoutAuthenticatedUsers,
    deleteLocalCartProductFromStateAndLocalStorageForWithoutAuthenticatedUsers,
    addLocalCartProductsFromLocalStorageToLogedInUserCartApi,
    applyInsuranceFeeApi,
    addLocalCartProductsToWPSessionUsingUniqueMutationIdApi,
    applyFeeNonBankTransferBooleanOnly,
  }

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}
