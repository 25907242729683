import axios from "axios"

export const callGraphQLQuery = async (
  query,
  variables = null,
  token = null
) => {
  try {
    const payload = { query: query }
    const headers = {
      "Content-Type": "application/json",
    }

    if (variables) payload["variables"] = variables
    if (token) headers["Authorization"] = `Bearer ${token}`

    const sessionID = sessionStorage.getItem("sessionID")
    if (sessionID) headers["X-Custom-Sessionid"] = `${sessionID}`

    const currency = JSON.parse(
      localStorage.getItem("currencyContextSessionValue")
    )
    if (currency) headers["X-Current-Currency"] = currency.code || "AUD"

    const country = localStorage.getItem("country")
    if (country && !token) headers["X-Current-Country"] = country

    const response = await axios.post(`${process.env.WP_API_URL}`, payload, {
      headers,
    })
    return response
  } catch (error) {
    console.error("Error fetching data:", error)
  }
}
