import axios from "axios"

export const formatNumberWithCommas = number => {
  return number.toLocaleString("en-US")
}

// export const formatPrice = (price, currency) => {
//   const updatedPrcie = (
//     parseFloat(price.toString().replace(/[^0-9.-]+/g, "")) * currency?.rate
//   ).toFixed(2)
//   return currency?.symbol + updatedPrcie
// }

export const formatPrice = (price, currency) => {
  const numPrice1 = parseFloat(
    price.toString().includes(currency?.symbol ?? "$")
      ? price.replace(currency?.symbol ?? "$", "")
      : +price
  )
  const updatedPrcie = parseFloat(numPrice1).toFixed(2)
  return currency?.symbol + updatedPrcie
}

export const formatPriceForNonAuth = (price, currency) => {
  const numPrice1 = parseFloat(
    price.toString().includes("$") ? price.replace("$", "") : +price
  )
  const updatedPrcie = parseFloat(numPrice1).toFixed(2)
  return currency?.symbol + updatedPrcie
}

const Utils = {
  calculatePriceDifference: (price1, price2) => {
    // const numPrice1 = parseFloat(price1.replace("$", ""))
    // const numPrice2 = parseFloat(price2.replace("$", ""))

    // Remove the $ sign only if it exists
    const numPrice1 = parseFloat(
      price1.toString().includes("$") ? price1.replace("$", "") : price1
    )
    const numPrice2 = parseFloat(
      price2.toString().includes("$") ? price2.replace("$", "") : price2
    )

    const difference = numPrice1 - numPrice2
    return `$${difference.toFixed(2)}`
  },

  calculatePriceDifferenceForAuthUser: (price1, price2, symbol) => {
    //console.log("===============>", { price1, price2, symbol })
    const numPrice1 = parseFloat(
      price1.toString().includes(symbol ?? "$")
        ? price1.replace(symbol ?? "$", "")
        : price1
    )
    const numPrice2 = parseFloat(
      price2.toString().includes(symbol ?? "$")
        ? price2.replace(symbol ?? "$", "")
        : price2
    )
    const difference = numPrice1 - numPrice2
    return `${symbol}${difference.toFixed(2)}`
  },

  calculatePriceDifferenceQty: (price1, price2, qty) => {
    // const numPrice1 = parseFloat(price1.replace("$", ""))
    // const numPrice2 = parseFloat(price2.replace("$", ""))
    // Remove the $ sign only if it exists
    const numPrice1 = parseFloat(
      price1.toString().includes("$") ? price1.replace("$", "") : price1
    )
    const numPrice2 = parseFloat(
      price2.toString().includes("$") ? price2.replace("$", "") : price2
    )

    const difference = qty * (numPrice1 - numPrice2)
    return `$${difference.toFixed(2)}`
  },

  getTotalDiscountAmount: discounts => {
    return discounts
      .reduce((total, discount) => {
        // Remove the "$" and parse the discount amount to a number
        const amount = parseFloat(discount.discountAmount.replace("$", ""))
        return total + amount
      }, 0)
      ?.toFixed(2)
  },

  checkIfAnyFilterValueExists: filterObject => {
    const keysToIgnore = ["after", "category", "first", "tag"]

    return Object.keys(filterObject || {})?.some(key => {
      return !keysToIgnore.includes(key) && filterObject[key] !== ""
    })
  },

  getIfAnyFilterValueExists: filterObject => {
    const keysToIgnore = ["after", "category", "first", "tag"]
    const filterValues = []
    Object.keys(filterObject).map(key => {
      if (!keysToIgnore.includes(key) && filterObject[key] !== "") {
        filterValues.push(key)
      }
    })
    return filterValues
  },

  validateEmail: email => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(email)
  },

  generateUniqueId: () => {
    // Get current date and time
    const now = new Date()

    // Format date and time components
    const date = now.toISOString().split("T")[0] // YYYY-MM-DD
    const time = now.toTimeString().split(" ")[0].replace(/:/g, "") // HHMMSS

    // Generate a random number for additional uniqueness
    const randomNum = Math.floor(Math.random() * 10000) // Random number between 0 and 9999

    // Combine all parts into a unique ID
    const uniqueId = `${date}-${time}-${randomNum}`

    return uniqueId
  },

  camelCaseToWords: (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase
      .replace(/^./, (char) => char.toUpperCase()); // Capitalize the first letter
  }
}

export default Utils

export const autoLogin = async () => {
  const authContextStorageValue =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : ""

  if (!authContextStorageValue?.username) {
    console.error("Unable to auto login as username & password NOT found")
    return
  }
  const { username, password } = authContextStorageValue
  // Get the variables from user input
  const variables = {
    username,
    password,
  }

  // Define the GraphQL query
  const query = `
    mutation LoginUser($username: String!, $password: String!) {
      login(input :{username: $username, password: $password}) {
        authToken
        customer {
          databaseId,
          username,
          email
        }
        
      }
    }
    `

  try {
    const response = await axios.post(
      `${process.env.WP_API_URL}`,
      {
        query: query,
        variables: variables,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer YOUR_AUTH_TOKEN`, // If authentication is needed
        },
      }
    )

    // Handle the response
    if (response.status === 200) {
      if (!response?.data?.data?.login) {
        console.error(
          "Unable to auto login. Please verify your username & password"
        )
        return
      }

      const { authToken } = response?.data?.data?.login
      const { username, email, databaseId } =
        response?.data?.data?.login?.customer

      return {
        authToken,
        username,
        email,
        databaseId,
      }
    }
  } catch (error) {
    console.error("Error fetching data:", error)
  }
}

export const countryNameByCode = {
  "AF": "Afghanistan",
  "AX": "Åland Islands",
  "AL": "Albania",
  "DZ": "Algeria",
  "AS": "American Samoa",
  "AD": "Andorra",
  "AO": "Angola",
  "AI": "Anguilla",
  "AQ": "Antarctica",
  "AG": "Antigua and Barbuda",
  "AR": "Argentina",
  "AM": "Armenia",
  "AW": "Aruba",
  "AU": "Australia",
  "AT": "Austria",
  "AZ": "Azerbaijan",
  "BS": "Bahamas",
  "BH": "Bahrain",
  "BD": "Bangladesh",
  "BB": "Barbados",
  "BY": "Belarus",
  "PW": "Belau",
  "BE": "Belgium",
  "BZ": "Belize",
  "BJ": "Benin",
  "BM": "Bermuda",
  "BT": "Bhutan",
  "BO": "Bolivia",
  "BQ": "Bonaire, Saint Eustatius and Saba",
  "BA": "Bosnia and Herzegovina",
  "BW": "Botswana",
  "BV": "Bouvet Island",
  "BR": "Brazil",
  "IO": "British Indian Ocean Territory",
  "BN": "Brunei",
  "BG": "Bulgaria",
  "BF": "Burkina Faso",
  "BI": "Burundi",
  "KH": "Cambodia",
  "CM": "Cameroon",
  "CA": "Canada",
  "CV": "Cape Verde",
  "KY": "Cayman Islands",
  "CF": "Central African Republic",
  "TD": "Chad",
  "CL": "Chile",
  "CN": "China",
  "CX": "Christmas Island",
  "CC": "Cocos (Keeling) Islands",
  "CO": "Colombia",
  "KM": "Comoros",
  "CG": "Congo (Brazzaville)",
  "CD": "Congo (Kinshasa)",
  "CK": "Cook Islands",
  "CR": "Costa Rica",
  "HR": "Croatia",
  "CU": "Cuba",
  "CW": "Curaçao",
  "CY": "Cyprus",
  "CZ": "Czech Republic",
  "DK": "Denmark",
  "DJ": "Djibouti",
  "DM": "Dominica",
  "DO": "Dominican Republic",
  "EC": "Ecuador",
  "EG": "Egypt",
  "SV": "El Salvador",
  "GQ": "Equatorial Guinea",
  "ER": "Eritrea",
  "EE": "Estonia",
  "SZ": "Eswatini",
  "ET": "Ethiopia",
  "FK": "Falkland Islands",
  "FO": "Faroe Islands",
  "FJ": "Fiji",
  "FI": "Finland",
  "FR": "France",
  "GF": "French Guiana",
  "PF": "French Polynesia",
  "TF": "French Southern Territories",
  "GA": "Gabon",
  "GM": "Gambia",
  "GE": "Georgia",
  "DE": "Germany",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GR": "Greece",
  "GL": "Greenland",
  "GD": "Grenada",
  "GP": "Guadeloupe",
  "GU": "Guam",
  "GT": "Guatemala",
  "GG": "Guernsey",
  "GN": "Guinea",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HT": "Haiti",
  "HM": "Heard Island and McDonald Islands",
  "HN": "Honduras",
  "HK": "Hong Kong",
  "HU": "Hungary",
  "IS": "Iceland",
  "IN": "India",
  "ID": "Indonesia",
  "IR": "Iran",
  "IQ": "Iraq",
  "IE": "Ireland",
  "IM": "Isle of Man",
  "IL": "Israel",
  "IT": "Italy",
  "CI": "Ivory Coast",
  "JM": "Jamaica",
  "JP": "Japan",
  "JE": "Jersey",
  "JO": "Jordan",
  "KZ": "Kazakhstan",
  "KE": "Kenya",
  "KI": "Kiribati",
  "KW": "Kuwait",
  "KG": "Kyrgyzstan",
  "LA": "Laos",
  "LV": "Latvia",
  "LB": "Lebanon",
  "LS": "Lesotho",
  "LR": "Liberia",
  "LY": "Libya",
  "LI": "Liechtenstein",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "MO": "Macao",
  "MG": "Madagascar",
  "MW": "Malawi",
  "MY": "Malaysia",
  "MV": "Maldives",
  "ML": "Mali",
  "MT": "Malta",
  "MH": "Marshall Islands",
  "MQ": "Martinique",
  "MR": "Mauritania",
  "MU": "Mauritius",
  "YT": "Mayotte",
  "MX": "Mexico",
  "FM": "Micronesia",
  "MD": "Moldova",
  "MC": "Monaco",
  "MN": "Mongolia",
  "ME": "Montenegro",
  "MS": "Montserrat",
  "MA": "Morocco",
  "MZ": "Mozambique",
  "MM": "Myanmar",
  "NA": "Namibia",
  "NR": "Nauru",
  "NP": "Nepal",
  "NL": "Netherlands",
  "NC": "New Caledonia",
  "NZ": "New Zealand",
  "NI": "Nicaragua",
  "NE": "Niger",
  "NG": "Nigeria",
  "NU": "Niue",
  "NF": "Norfolk Island",
  "KP": "North Korea",
  "MK": "North Macedonia",
  "MP": "Northern Mariana Islands",
  "NO": "Norway",
  "OM": "Oman",
  "PK": "Pakistan",
  "PS": "Palestinian Territory",
  "PA": "Panama",
  "PG": "Papua New Guinea",
  "PY": "Paraguay",
  "PE": "Peru",
  "PH": "Philippines",
  "PN": "Pitcairn",
  "PL": "Poland",
  "PT": "Portugal",
  "PR": "Puerto Rico",
  "QA": "Qatar",
  "RE": "Reunion",
  "RO": "Romania",
  "RU": "Russia",
  "RW": "Rwanda",
  "BL": "Saint Barthélemy",
  "SH": "Saint Helena",
  "KN": "Saint Kitts and Nevis",
  "LC": "Saint Lucia",
  "SX": "Saint Martin (Dutch part)",
  "MF": "Saint Martin (French part)",
  "PM": "Saint Pierre and Miquelon",
  "VC": "Saint Vincent and the Grenadines",
  "WS": "Samoa",
  "SM": "San Marino",
  "ST": "São Tomé and Príncipe",
  "SA": "Saudi Arabia",
  "SN": "Senegal",
  "RS": "Serbia",
  "SC": "Seychelles",
  "SL": "Sierra Leone",
  "SG": "Singapore",
  "SK": "Slovakia",
  "SI": "Slovenia",
  "SB": "Solomon Islands",
  "SO": "Somalia",
  "ZA": "South Africa",
  "GS": "South Georgia/Sandwich Islands",
  "KR": "South Korea",
  "SS": "South Sudan",
  "ES": "Spain",
  "LK": "Sri Lanka",
  "SD": "Sudan",
  "SR": "Suriname",
  "SJ": "Svalbard and Jan Mayen",
  "SE": "Sweden",
  "CH": "Switzerland",
  "SY": "Syria",
  "TW": "Taiwan",
  "TJ": "Tajikistan",
  "TZ": "Tanzania",
  "TH": "Thailand",
  "TL": "Timor-Leste",
  "TG": "Togo",
  "TK": "Tokelau",
  "TO": "Tonga",
  "TT": "Trinidad and Tobago",
  "TN": "Tunisia",
  "TR": "Turkey",
  "TM": "Turkmenistan",
  "TC": "Turks and Caicos Islands",
  "TV": "Tuvalu",
  "UG": "Uganda",
  "UA": "Ukraine",
  "AE": "United Arab Emirates",
  "GB": "United Kingdom",
  "US": "United States",
  "UM": "United States Minor Outlying Islands",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VU": "Vanuatu",
  "VA": "Vatican",
  "VE": "Venezuela",
  "VN": "Vietnam",
  "VG": "Virgin Islands (British)",
  "VI": "Virgin Islands (U.S.)",
  "WF": "Wallis and Futuna",
  "EH": "Western Sahara",
  "YE": "Yemen",
  "ZM": "Zambia",
  "ZW": "Zimbabwe"
}

