import { Link } from "gatsby"
import { debounce } from "lodash"
import React, { useCallback, useContext, useState } from "react"
import { CartContext } from "../store/cartContext"
import Utils, { formatPrice, formatPriceForNonAuth } from "../helper/utills"
import { AuthContext } from "../store/authContext"
import Price from "../helper/price"
import { CurrencyContext } from "../store/currencyContext"
import CustomToast from "../helper/toast"
import { CART_msg } from "../helper/constants"

const roundedAmount = (number = 0) =>
  (Math.round(number * 100) / 100).toFixed(2)

export default function CartItemCard({ cartItem, selectedCurrency }) {
  const currencyContext = useContext(CurrencyContext)
  const { currencyContextData } = currencyContext
  const updatedCurrencyContextData = { ...currencyContextData }
  updatedCurrencyContextData.rate = "1"

  const cartContext = useContext(CartContext)
  const {
    updateCartItems,
    removeSingleCartItemUsingProductId,
    showLoader,
    removeLocalCartProductFromStateAndLocalStorageForWithoutAuthenticatedUsers,
    addLocalCartProductToStateAndLocalStorageForWithoutAuthenticatedUsers,
    deleteLocalCartProductFromStateAndLocalStorageForWithoutAuthenticatedUsers,
  } = cartContext

  const authContext = useContext(AuthContext)
  const { authContextData: authToken } = authContext

  const [count, setCount] = useState(cartItem.quantity ?? 0)
  const [loading, setLoading] = useState(false)
  const [currentId, setCurrentId] = useState(null)

  const updateCartDebounced = useCallback(
    debounce(async newCount => {
      setLoading(true)
      try {
        if (!authToken) {
          await updateCartItems(cartItem?.product?.node?.databaseId, newCount)
        } else {
          await updateCartItems(cartItem.key, newCount)
        }

        //console.log("Cart updated successfully:", newCount)
      } catch (error) {
        console.error("Failed to update cart:", error)
        // Revert the count if the API call fails
        setCount(prevCount => prevCount)
      } finally {
        setLoading(false)
      }
    }, 500),
    []
  )

  const handleCountChange = (newCount, type) => {
    //if (authToken) {
    if (newCount >= 0) {
      setCount(newCount)
      updateCartDebounced(newCount)
    }

    /*
    } else {
      setCount(newCount)
      if (type === "inc") {
        addLocalCartProductToStateAndLocalStorageForWithoutAuthenticatedUsers(
          cartItem?.product?.node
        )
      } else if (type === "dec") {
        removeLocalCartProductFromStateAndLocalStorageForWithoutAuthenticatedUsers(
          cartItem?.product?.node?.id
        )
      }
      CustomToast(CART_msg.PUOCS, "success")
    }
    */
  }
  return (
    <tr>
      <td>
        <Link to={cartItem?.product?.node?.uri}>
          <img
            src={cartItem?.product?.node?.featuredImage?.node?.sourceUrl}
            alt={cartItem?.product?.node?.featuredImage?.node?.altText}
          />
        </Link>
      </td>
      <td>
        <div className="product_details_cart">
          <Link to={cartItem?.product?.node?.uri}>
            {cartItem?.product?.node?.name}
          </Link>
          {/* {
            cartItem?.product?.node?.stockStatus === "ON_BACKORDER" ? (
              <div className="backorder_box">Available on backorder</div>
            ) : cartItem?.product?.node?.stockStatus === "IN_STOCK" ? (
              <div className="backorder_box">In Stock</div>
            ) : <div className="backorder_box">{cartItem?.product?.node?.stockStatus}</div>
          } */}
          <div className="backorder_box">
            {cartItem?.product?.node?.availabilityText === "In Stock" ? `${cartItem?.product?.node?.stockQuantity} ${cartItem?.product?.node?.availabilityText}` : cartItem?.product?.node?.availabilityText}
          </div>
          <div className="product_price">
            {cartItem?.product?.node?.salePrice ? (
              <>
                <span className="backorder_box">
                  Save{" "}
                  {Utils.calculatePriceDifferenceForAuthUser(
                    cartItem?.product?.node?.regularPrice ?? 0,
                    cartItem?.product?.node?.salePrice ?? 0,
                    // currencyContext?.currencyContextData?.symbol
                    selectedCurrency
                  )}
                  { }
                </span>
                <Price
                  regularPrice={cartItem?.product?.node?.regularPrice}
                  salePrice={cartItem?.product?.node?.salePrice}
                  // currency={currencyContext.currencyContextData}
                  currency={{
                    symbol: selectedCurrency,
                  }}
                  isRequiredCurrencySymball={true}
                  count={count}
                  quantityDiscounts={cartItem?.product?.node?.quantityDiscounts}
                />
              </>
            ) : (
              <Price
                regularPrice={cartItem?.product?.node?.regularPrice}
                salePrice={cartItem?.product?.node?.salePrice}
                // currency={currencyContext.currencyContextData}
                currency={{
                  symbol: selectedCurrency,
                }}
                isRequiredCurrencySymball={true}
                count={count}
                quantityDiscounts={cartItem?.product?.node?.quantityDiscounts}
              />
            )}
          </div>
          <div className="quantity_remove">
            <div className="quantity">
              <button
                onClick={() => handleCountChange(+count - 1, "dec")}
                disabled={loading || +count <= 1}
              >
                -
              </button>
              <input
                type="number"
                step="1"
                min="0"
                max="9999"
                value={count}
                onChange={e => handleCountChange(parseInt(e.target.value, 10))}
                disabled={loading || !authToken}
              />
              <button
                onClick={() => handleCountChange(+count + 1, "inc")}
                disabled={loading || cartItem?.product?.node?.stockQuantity === count}
              >
                +
              </button>
            </div>
            {loading && (
              <div>
                <span>Updating cart...</span>
              </div>
            )}
            {showLoader && cartItem.key === currentId ? (
              <div>
                <span>Removing cart item...</span>
              </div>
            ) : (
              <button
                className="remove-link"
                onClick={() => {
                  if (authToken) {
                    setCurrentId(cartItem.key)
                    removeSingleCartItemUsingProductId(cartItem.key)
                  } else {
                    // deleteLocalCartProductFromStateAndLocalStorageForWithoutAuthenticatedUsers(
                    //   cartItem?.product?.node?.id
                    // )
                    setCurrentId(cartItem.key)
                    removeSingleCartItemUsingProductId(
                      cartItem?.product?.node?.databaseId
                    )
                  }
                }}
              >
                Remove item
              </button>
            )}
          </div>
        </div>
      </td>
      <td>
        <div className="wc-block-cart-item__total-price-and-sale-badge-wrapper">
          <span className="price wc-block-components-product-price">
            <span className="wc-block-formatted-money-amount wc-block-components-formatted-money-amount wc-block-components-product-price__value">
              {selectedCurrency}
              {roundedAmount(cartItem?.subtotal)}
            </span>
          </span>
        </div>
      </td>
    </tr>
  )
}
